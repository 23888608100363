import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/episode-layout.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "ep7---hayden-barnes-from-being-a-lawyer-to-becoming-an-engineering-manager-at-canonical"
    }}>{`Ep7 - Hayden Barnes: From being a lawyer to becoming an engineering manager at Canonical`}</h1>
    <div className="soundcloud-iframe">
    <iframe className="mb-0" width="100%" height="20" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/931481566&color=%23fc4056&inverse=true&auto_play=false&show_user=true"></iframe>
    </div>
    <Link className="link-button mr-5" to="/transcripts/ep7-hayden-barnes/" mdxType="Link">Transcript</Link>
    <a className="link-button" href="https://youtu.be/DnSa0VUKJd4">Watch this talk</a>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`On this episode, Haylen Barnes shares his journey from being a lawyer to becoming an engineering manager at canonical. We talk about windows, windows subsystem for Linux, WSLconf and all things Linux.`}</p>
    <p>{`Hayden shows how his tinkering and hacking mentality opened a door in his career that he didn't even consider. How his project Pewgin came to be and how working on it landed a job offer from Canonical.`}</p>
    <p>{`He also shares great pieces of advice for anyone that wants to differentiate itself from others. How important finding a niche and creating content. Hayden also talks about the dangers of turning your hobbies into a job.`}</p>
    <h2 {...{
      "id": "guest-information"
    }}>{`Guest Information`}</h2>
    <div className="episode-card">
    <img src="https://landingintech.com/hayden-barnes.jpg" alt="Hayden Barnes" className="guest-avatar" />
    <div className="mx-8 pt-5">
        <p className="episode-title text-xl">Hayden Barnes</p>
        <p>Engineering Manager</p>
        <p>
            <a href="https://boxofcables.dev"> Website</a> | <a href="https://twitter.com/unixterminal"> Twitter</a> | <a href="https://github.com/sirredbeard"> Github</a>
        </p>
    </div>
    </div>
    <h2 {...{
      "id": "resources-and-links"
    }}>{`Resources and Links`}</h2>
    <p>{`Through the talk we have mentioned a lot of interesting projects, books, videos and other resources, you can find all about them in this section.`}</p>
    <p><em parentName="p"><strong parentName="em">{`Note`}</strong>{`: Any link to amazon is an affiliate link which means if you buy something from the link, it will not cost you more but we get paid a percentage from amazon which helps with the expenses of running the show.`}</em></p>
    <h3 {...{
      "id": "open-source"
    }}>{`Open Source`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/WhitewaterFoundry/Pengwin"
        }}>{`Pengwin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ubuntu.com/"
        }}>{`Ubuntu`}</a></li>
    </ul>
    <h3 {...{
      "id": "other-resources"
    }}>{`Other Resources`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://canonical.com/"
        }}>{`Canonical`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.wslconf.dev/"
        }}>{`WSLConf`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.edx.org/"
        }}>{`Edx`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://omz-software.com/pythonista/"
        }}>{`Pytonista`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      